import React from "react"
import SEO from "../../../components/seo"
// import Button from "../../components/button"
import Footer from "../../../components/footer"
import Layout from "../../../components/layout"
// import { vP } from "../../components/gutters"
import Container from "../../../components/container"
import presets, { colors } from "../../../utils/presets"
import { itemListProd } from "../../../utils/sidebar/item-list"
import typography, { rhythm, scale } from "../../../utils/typography"
// import pic1 from "../../images/technologyhighlights/s-technology.jpg"
// import pic2 from "../../images/technologyhighlights/change2017031601.jpg"
import { graphql } from "gatsby"

const itsmPage = (props) => {
  const { edges } = props.data.allMarkdownRemark
  const _header = edges.find(({node: item}) => item.frontmatter.topTitle)
  return (
      <Layout location={props.location} itemList={itemListProd} contentTitle={_header.node.frontmatter.topTitle}>
          <SEO title="简易流程" />
          <Container overrideCSS={{padding: 24}}>
            {edges
              .map(({ node: card }) => {
                return (
                  <div key={card.id} id={card.frontmatter.id} className="anchor-point">
                    <h4 css={styles.summaryTitle}>{card.frontmatter.title}</h4>
                    <div dangerouslySetInnerHTML={{ __html: card.html }}></div>
                  </div>
                )
              })
            }
          </Container>
      </Layout>
  )
}

const styles = {
    contentHeader: {
        alignItems: `center`,
        background: `rgba(255,255,255,0.98)`,
        borderBottom: `1px solid ${colors.ui.light}`,
        display: `flex`,
        flexDirection: `row`,
        height: presets.headerHeight,
        paddingLeft: `${rhythm(3 / 4)}`,
        paddingRight: `${rhythm(3 / 4)}`,
        zIndex: 1,
    },
    contentTitle: {
        ...scale(1),
        color: colors.skyDark,
        lineHeight: 1,
        margin: 0,
    },
    footer: {
        background: `dimGray`,
        fontFamily: typography.options.headerFontFamily.join(`,`),
        paddingTop: 0,
        paddingBottom: 0,
    },
    sticky: {
      position: `sticky`,
      top: `calc(${presets.bannerHeight} - 1px)`,
      [presets.Desktop]: {
        top: `calc(${presets.headerHeight} + ${presets.bannerHeight} - 1px)`,
      },
    },
    summaryTitle: {
        margin: `0 0 10px 0`,
        padding: '0 15px 15px 0',
        alignItems: `center`,
        borderBottom: `1px solid ${colors.ui.border}`,
        display: `flex`,
        flexDirection: `row`,
        zIndex: 1,
    },
}

export default itsmPage

export const pageQuery = graphql`
  query itsmQuery {
    allMarkdownRemark(
      sort: {fields: [frontmatter___order], order:ASC }
      filter: { frontmatter: { type: { eq: "itsm" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            id
            title
            topTitle
          }
        }
      }
    }
  }
`
